import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import SegmentConsentManager from "./SegmentConsentManager"

// import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component<{
  location: any
  title: string
  children: any
}> {
  render() {
    return (
      <StaticQuery
        query={query}
        render={({
          site: {
            siteMetadata: { author, social },
          },
        }) => {
          const { location, title, children } = this.props
          // @ts-ignore
          const rootPath = `${__PATH_PREFIX__}/`
          let header

          if (location.pathname === rootPath) {
            header = (
              <h1 className="text-5xl font-black font-sans mb-10 mt-0">
                <Link className="shadow-none" to={`/`}>
                  {title}
                </Link>
              </h1>
            )
          } else {
            header = (
              <h3 className="text-2xl font-sans font-black mt-0">
                <Link className="shadow-none" to={`/`}>
                  {title}
                </Link>
              </h3>
            )
          }
          return (
            <>
              <div className="max-w-2xl mx-auto px-5 py-10">
                <header>{header}</header>
                <main>{children}</main>
                <footer>
                  © {new Date().getFullYear()}.{" "}
                  Made with <small>♥</small> by{" "}
                  <a
                    className="text-blue-600"
                    href="https://clerklabs.com"
                    target="_blank"
                  >
                    Clerk Labs
                  </a>
                </footer>
              </div>
              <div className="absolute bottom-0 w-full">
                <SegmentConsentManager />
              </div>
            </>
          )
        }}
      />
    )
  }
}

export default Layout

const query = graphql`
  query {
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`
